<template>
  <w-layout v-if="totalItems > 0" align-center justify-center row>
    <w-flex v-if="itemName" shrink>
      {{
        $t("pagination.number_of_items", {
          end:
            pageNumber * rowsPerPage > totalItems ? totalItems : pageNumber * rowsPerPage,
          name: itemName,
          start: pageNumber === 1 ? 1 : (pageNumber - 1) * rowsPerPage,
          total: totalItems,
        })
      }}
    </w-flex>
    <w-flex>
      <v-pagination
        circle
        color="primary "
        :length="numberOfPages"
        :value="pageNumber"
        :total-visible="totalVisible"
        @input="setPaginationPage($event)"
      />
    </w-flex>
    <w-flex shrink>
      <w-select
        :items="[5, 10, 15, 25, 50]"
        :value="rowsPerPage"
        @input="setPaginationRowsPerPage($event)"
      />
    </w-flex>
    <w-flex v-if="itemName" shrink>{{
      $t("pagination.item_per_page", {
        name: itemName,
      })
    }}</w-flex>
  </w-layout>
</template>

<script>
/**
 * @displayName w-pagination
 *
 * @since 0.3.2
 */
export default {
  name: "WPagination",
  props: {
    itemName: {
      default: null,
      required: false,
      type: String,
    },
    pageNumber: {
      required: true,
      type: Number,
    },
    rowsPerPage: {
      required: true,
      type: Number,
    },
    totalItems: {
      required: true,
      type: Number,
    },
    totalVisible: {
      required: false,
      type: Number,
      default: undefined,
    },
  },
  computed: {
    numberOfPages: function () {
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
  },
  methods: {
    setPaginationPage: function (pageNumber) {
      if (this.pageNumber != pageNumber) {
        this.$emit("update:page-number", pageNumber);
      }
    },
    setPaginationRowsPerPage: function (rowsPerPage) {
      if (this.rowsPerPage != rowsPerPage) {
        this.$emit("update:rows-per-page", rowsPerPage);
      }
    },
  },
};
</script>
